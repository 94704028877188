import React, { useState, useRef, useEffect } from "react";
import PageLayout from "../pagelayout/pagelayout";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/actions/userAction";
import "./bookEventAdmin.css";
import { MultiSelect } from "react-multi-select-component";
import DataTable from "react-data-table-component";
import search from "../../assets/images/search.svg";
import AddGamesBookEvent from "../modal/addGamesBookEvent";
import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import { getAllPaymentForAdmin, verifyOfflinePayment } from "../../redux/actions/paymentApiActions";
import useDebouncedSearch from "../../helpers/debounce";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { failureAlert, IsAdmin, successAlert, Frontned_URL } from "../../helpers/helper";
import {
  S3_BASE_URL,
  BASE_URL,
  GOOGLE_RECAPTCHA_SITE_KEY,
  axiosApiInstance,
  encryptData,
  decryptData
} from "../../helpers/helper";

import LoadingComponent from "../loader/LoadingComponent";
import { SYMBOLS } from "../../constants";
import { log } from "synonyms/dictionary";
import { uploadFile } from '../../redux/actions/commonActions';
import Select from "react-select";
import deleteIcon from '../../assets/images/delete.svg';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ReactSlider from 'react-slider';
const localizer = momentLocalizer(moment);
const BookEventAdmin = (props) => {
  const [events, setEvents] = useState([]);
  const [datesToSend, setDatesToSend] = useState([]);
  const [combineArr, setCombineArr] = useState([]);
  const [eventDates, setEventDates] = useState([]);

  //   const SupportedVideoPlatforms = [
  //     { value: 'zoom', label: 'Zoom' },
  //     { value: 'microsoft_teams', label: 'Microsoft Teams' },
  //     { value: 'cisco_webex', label: 'Cisco Webex' },
  //     { value: 'google_meet', label: 'Google Meet' },
  //     { value: 'gotomeeting', label: 'Gotomeeting' },
  //     { value: 'blue_jeans', label: 'Blue Jeans' },

  // ];

  useEffect(() => {
    getAllPlatforms();
  
  }, []);
  const handleSelectSlot = (slotInfo) => {
    const message = window.prompt('Enter Event Details');
    if (message) {
      const formattedDate = moment(slotInfo.start).format('YYYY-MM-DD');
      const newEvent = {
        title: message,
        start: slotInfo.start,
        end: slotInfo.start,
        allDay: true,
        message,
      };
      setEvents((prevEvents) => [...prevEvents, newEvent]);
      setEventDates((prevDates) => [...prevDates, formattedDate]);

    }
  };

  const handleSelectEvent = (event) => {
    const isRemove = window.confirm(`Message: ${event.title}\nDo you want to remove this event?`);
    if (isRemove) {
      setEvents((prevEvents) => prevEvents.filter(e => e !== event));
    }
  };

  const eventPropGetter = () => {
    return {
      style: {
        backgroundColor: 'lightblue',
        color: 'black',
        border: 'none',
        borderRadius: '5px',
      },
    };
  };

  const handleGetAllDates = async () => {
    const formattedEvents = events.map(event => {
      const date = new Date(event.start);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return {
        title: event.title,
        date: `${year}-${month}-${day}`
      };
    });
    if (formattedEvents.length > 0) {
      setLoaded(false);
      let obj = { blockDates: formattedEvents };
      const result = await axiosApiInstance
        .post(BASE_URL + "/api/bookEvent/addBlockDates", obj)
        .then((response) => {
          successAlert("Dates updated successfully");
          getAllBlockedDates();
          setLoaded(true);
        })
        .catch((error) => {
          failureAlert("Failed to add request");
          setLoaded(true);
        });
    }
  };
  const initialFormData = {
    title: "",
    description: "",
    feature: "",
    minPlayer: "",
    maxPlayer: "",
    duration: "",
    eventCost: "",
    eventCostUSD: "",
    eventType: "",
    categories: [],
    difficultylevel: "",
    gameType: "",
    bookingType: "",
    guaranteeType: "",
    headCount: "",
    howItWorks: "",
    agenda: "",
    thingsYouNeed: "",
    // howToJoin: "",
    breakOutRoom: "",
    breakOutRoomSize: "",
    supportedPlatforms: []
  };
  const [formData, setFormData] = useState(initialFormData);
  const [openBookEventModal, setOpenBookEventModal] = useState(false);
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);

  const [errors, setErrors] = useState({
    title: "",
    description: "",
    feature: "",
    minPlayer: "",
    maxPlayer: "",
    duration: "",
    eventCost: "",
    eventCostUSD: "",
    eventType: "",
    categories: [],
    difficultylevel: "",
    gameType: "",
    bookingType: "",
    guaranteeType: "",
    headCount: "",
    howItWorks: "",
    agenda: "",
    thingsYouNeed: "",
    // howToJoin: "",
    breakOutRoom: "",
    breakOutRoomSize: "",
    supportedPlatforms: []
  });
  const handleTextChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  const [loaded, setLoaded] = useState(true);
  const validateForm = () => {
    let valid = true;
    const newErrors = {
      title: "",
      description: "",
      feature: "",
      minPlayer: "",
      maxPlayer: "",
      duration: "",
      eventCost: "",
      eventCostUSD: "",
      eventType: "",
      categories: [],
      difficultylevel: "",
      gameType: "",
      bookingType: "",
      guaranteeType: "",
      headCount: "",
      howItWorks: "",
      agenda: "",
      thingsYouNeed: "",
      // howToJoin: "",
      breakOutRoom: "",
      breakOutRoomSize: "",
      supportedPlatforms: []

    };

    if (!formData.title) {
      newErrors.title = "Required";
      valid = false;
    }
    if (!formData.description) {
      newErrors.description = "Required";
      valid = false;
    }
    if (!formData.feature) {
      newErrors.feature = "Required";
      valid = false;
    }
    if (formData.minPlayer === '' || formData.minPlayer < 1 || formData.minPlayer > 10000) {
      newErrors.minPlayer = 'minPlayer must be between 1 and 10000';
      valid = false;
    }
    if (formData.maxPlayer === '' || formData.maxPlayer < 1 || formData.maxPlayer > 10000) {
      newErrors.maxPlayer = 'maxPlayer must be between 1 and 10000';
      valid = false;
    }
    if (!formData.duration) {
      newErrors.duration = "Required";
      valid = false;
    }
    if (!formData.eventCost) {
      newErrors.eventCost = "Required";
      valid = false;
    }
    if (!formData.eventCostUSD) {
      newErrors.eventCostUSD = "Required";
      valid = false;
    }
    if (!formData.eventType) {
      newErrors.eventType = "Required";
      valid = false;
    }
    if (formData.categories.length == 0) {
      newErrors.categories = "Required";
      valid = false;
    }
    if (!formData.difficultylevel) {
      newErrors.difficultylevel = "Required";
      valid = false;
    }
    if (!formData.gameType) {
      newErrors.gameType = "Required";
      valid = false;
    }
    if (!formData.bookingType) {
      newErrors.bookingType = " Required";
      valid = false;
    }
    if (!formData.guaranteeType) {
      newErrors.guaranteeType = " Required";
      valid = false;
    }
    if (!formData.headCount) {
      newErrors.headCount = "Required";
      valid = false;
    }
    if (!formData.howItWorks) {
      newErrors.howItWorks = "Required";
      valid = false;
    }

    if (!formData.agenda) {
      newErrors.agenda = "Required";
      valid = false;
    }
    if (!formData.thingsYouNeed) {
      newErrors.thingsYouNeed = "Required";
      valid = false;
    }
    // if (!formData.howToJoin) {
    //   newErrors.howToJoin = "Required";
    //   valid = false;
    // }

    if (!formData.breakOutRoom) {
      newErrors.breakOutRoom = "Required";
      valid = false;
    }
     //////////////////





     if (formData.breakOutRoom == 'yes') {
      if (formData.breakOutRoomSize === '' || formData.breakOutRoomSize < 1 || formData.breakOutRoomSize > 10000) {
        newErrors.breakOutRoomSize = 'Size must be between 1 and 10000';
        valid = false;
      }
    }

    if (formData.supportedPlatforms.length == 0) {
      newErrors.supportedPlatforms = "Required";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };
  const [categories, setCategories] = useState([]);
  const [gamesImages, setGamesImages] = useState([]);
  const [blockedDates, setBlockedDates] = useState([]);
  const [banners, setBanners] = useState([]);
  const isAdmin = IsAdmin();
  const dispatch = useDispatch();
  const [fetch, fetchGames] = useState(false);
  const signOut = async () => {
    await dispatch(logOut());
    if (isAdmin) props.history.push("/admin");
    else props.history.push("/");
  };

  const [bookEventGames, setBookEventGames] = useState([]);
  const [bookEventCategories, setBookEventCategories] = useState([]);
  const [bookEventplatforms, setBookEventPlatforms] = useState([]);
  const [formData2, setFormData2] = useState({
    textInput1: '',
    fileInput: null,
  });
  const [faqsData, setfaqsData] = useState([]);
  const [testimonialData, setTestimonialData] = useState([]);
  const [idsArr, setGamesIdsForCategory] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const [categoryTitle, setCategoryTitle] = useState([]);
  const [errors2, setErrors2] = useState({});
  const [AllGames, setAllGamesGames] = useState([]);
  const [multiSelectOptions, setMultiSelectOptions] = useState([]);
  const [multiSelectOptionsPlatforms, setMultiSelectOptionsplatforms] = useState([]);
  const dummyBlockedDates = [
    {
      message: "Meeting with client",
      date: "2024-10-09"
    },
    {
      message: "Team outing",
      date: "2024-10-10"
    },
    {
      message: "Project deadline",
      date: "2024-10-12"
    }
  ];


  const getAllCategories = async () => {
    try {
      const response = await axiosApiInstance.get(BASE_URL + "/api/bookEvent/getAllCategories");
      const result = response.data.data;

      setCategories(response.data.data);
      const options = result.map(item => ({
        value: item.id,
        label: item.title,
      }));
      setBookEventCategories(options);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };
  const getAllBlockedDates = async () => {
    try {
      const response = await axiosApiInstance.get(BASE_URL + "/api/bookEvent/getBlockedDates");

      const result = JSON.parse(response?.data?.data[0]?.blockedDates);

      setDatesToSend(result);

      const transformedEvents = result.map(blockedDate => ({
        start: new Date(blockedDate.date),
        end: new Date(blockedDate.date),
        title: blockedDate.title
      }));

      setEvents(transformedEvents);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };
  const getAllBanners = async () => {
    try {
      const response = await axiosApiInstance.get(BASE_URL + "/api/bookEvent/getBookEventBanners");

      setBanners(response.data.data);

    } catch (error) {
      console.log(error)
    }
  };

  const getAllGames = async () => {
    try {
      const response = await axiosApiInstance.get(BASE_URL + "/api/bookEvent/getBookEventAllGames");
      const result = response.data.data;

      setAllGamesGames(result);
      const options = result.map(item => ({
        value: item.id,
        label: item.title,
      }));
      setBookEventGames(options);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const getAllMainImages = async (gameid) => {
    try {
      const response = await axiosApiInstance.get(`${BASE_URL}/api/bookEvent/getAllMainImages/${gameid}`);
      const GamesImages = response.data.data;
      const updatedRows = GamesImages.map(item => ({
        images: [
          `${item.id}`,
          `${item.desktopBannerPath}`
        ],
        errors: ['', '']
      }));

      setMainGamesRows(updatedRows);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const getAllGamesImages = async (gameid) => {
    try {
      const response = await axiosApiInstance.get(`${BASE_URL}/api/bookEvent/getAllGamesImages/${gameid}`);
      setGamesImages(response.data.data);
      const GamesImages = response.data.data;
      const updatedRows = GamesImages.map(item => ({
        images: [
          `${item.id}`,
          `${item.desktopBannerPath}`
        ],
        errors: ['', '']
      }));
      setGamesRows(updatedRows);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };
  useEffect(() => {
    getAllCategories();
    getAllBlockedDates();
    getAllGames();
  }, []);


  const [selectedRowId, setSelectedRowId] = useState(null);
  const [activeTab, setActiveTab] = useState('Categories');
  const [bannerFileToUpload, setBannerFileToUpload] = useState(null);
  const [mobileBannerFileToUpload, setMobileBannerFileToUpload] = useState(null);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab == 'Testimonials') {
      getAllBanners();
    }
  };
  const [selectedBannerImage, setBannerSelectedImage] = useState(null);
  const [selectedMobileBannerImage, setMobileBannerSelectedImage] = useState(null);
  const [bannererror, setBannerError] = useState('');
  const [mobileBannererror, setMobileBannerError] = useState('');
  const [categoryName, setcategoryName] = useState('');
  const handlecategoryNameChange = (e) => {
    const value = e.target.value;
    setcategoryName(value);
    setcategoryNameError(validatecategoryName(value));
  };
  const validatecategoryName = (value) => {
    return value.trim() ? '' : 'Please fill out this field';
  };
  const fileInputRefCategoryImage = useRef(null);
  const fileInputRefGameBanner = useRef(null);
  const InputRefDesktopBanner = useRef(null);
  const InputRefMobileBanner = useRef(null);
  const [fileError, setFileError] = useState('');
  const [gameBannerFileError, setGameBannerFileError] = useState('');
  const [preview, setPreview] = useState('');
  const [gameBannerPreview, setGameBannerPreview] = useState('');
  const [desktopBannerPreview, setBannerPreview] = useState('');
  const [mobileBannerPreview, setMobileBannerPreview] = useState('');
  const [categoryNameError, setcategoryNameError] = useState('');
  const [GameBannerpathOnEdit, setGameBannerPathOnEdit] = useState('');
  const handleSubmitForm = async (e) => {
    e.preventDefault();

    const file = fileInputRefCategoryImage.current.files[0];
    const nameError = validatecategoryName(categoryName);
    setcategoryNameError(nameError);
    const fileError = await validateFile(file);
    setFileError(fileError);
    if (!nameError && !fileError) {
      try {
        const formData = new FormData();
        formData.append("company-logos", file);
        setLoaded(false);
        const response2 = await dispatch(uploadFile(formData));
        let obj = {
          title: categoryName,
          categoryImage: response2.data.data.path
        };
        const result = await axiosApiInstance
          .post(BASE_URL + "/api/bookEvent/addBookEventCategory", obj)
          .then((response) => {

            successAlert("Category added successfully");
            getAllCategories();
            setLoaded(true);
          })
          .catch((error) => {
            failureAlert("Failed to add request");
            setLoaded(true);
          });

        setcategoryName('')
        document.getElementById('categoryImage').value = '';
        setPreview('')
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    }
  };

  const handleGameBannerChangeOnEdit = async (e) => {
    const file = e.target.files[0];
    await validateGameBannerFileOnEdit(file);
  };

  const validateGameBannerFileOnEdit = (file) => {
    return new Promise((resolve) => {
      if (!file) {
        setGameBannerFileError('Please select a file.');
        setGameBannerPreview('');
        resolve('Please select a file.');
      } else if (file.size > 10 * 1024 * 1024) {
        setGameBannerFileError('File size should not exceed 10MB.');
        setGameBannerPreview('');
        resolve('File size should not exceed 10MB.');
      } else {
        const img = new Image();
        const url = URL.createObjectURL(file);
        img.onload = async () => {
          if (img.width !== 400 || img.height !== 200) {
            setGameBannerFileError('Image dimensions must be 400 x 200 pixels.');
            setGameBannerPreview('');
            resolve('Image dimensions must be 300 x 300 pixels.');
          } else {

            const result = await uploadImage(file);

            setGameBannerPathOnEdit(result);
            setGameBannerPreview(result)

          }
        };
        img.onerror = () => {
          setGameBannerFileError('Invalid image file.');
          setGameBannerPreview('');
          resolve('Invalid image file.');
        };
        img.src = url;
      }
    });
  };

  const handleGameBannerChange = async (e) => {
    const file = e.target.files[0];
    await validateGameBannerFile(file);
  };

  const validateGameBannerFile = (file) => {
    return new Promise((resolve) => {
      if (!file) {
        setGameBannerFileError('Please select a file.');
        setGameBannerPreview('');
        resolve('Please select a file.');
      } else if (file.size > 10 * 1024 * 1024) {
        setGameBannerFileError('File size should not exceed 10MB.');
        setGameBannerPreview('');
        resolve('File size should not exceed 10MB.');
      } else {
        const img = new Image();
        const url = URL.createObjectURL(file);
        img.onload = () => {
          if (img.width !== 400 || img.height !== 200) {
            setGameBannerFileError('Image dimensions must be 400 x 200 pixels.');
            setGameBannerPreview('');
            resolve('Image dimensions must be 400 x 200 pixels.');
          } else {
            setGameBannerFileError('');
            setGameBannerPreview(url);
            resolve('');
          }
        };
        img.onerror = () => {
          setGameBannerFileError('Invalid image file.');
          setGameBannerPreview('');
          resolve('Invalid image file.');
        };
        img.src = url;
      }
    });
  };


  const handleCategoryFileChange = async (e) => {
    const file = e.target.files[0];
    await validateFile(file);
  };

  const validateFile = (file) => {
    return new Promise((resolve) => {
      if (!file) {
        setFileError('Please select a file.');
        setPreview('');
        resolve('Please select a file.');
      } else if (file.size > 10 * 1024 * 1024) {
        setFileError('File size should not exceed 10MB.');
        setPreview('');
        resolve('File size should not exceed 10MB.');
      } else {
        const img = new Image();
        const url = URL.createObjectURL(file);
        img.onload = () => {
          if (img.width !== 150 || img.height !== 150) {
            setFileError('Image dimensions must be 150 x 150 pixels.');
            setPreview('');
            resolve('Image dimensions must be 150 x 150 pixels.');
          } else {
            setFileError('');
            setPreview(url);
            resolve('');
          }
        };
        img.onerror = () => {
          setFileError('Invalid image file.');
          setPreview('');
          resolve('Invalid image file.');
        };
        img.src = url;
      }
    });
  };

  const handleBannerChange = async (e) => {
    const file = e.target.files[0];
    await validateDesktopBannerFile(file);
  };
  const validateDesktopBannerFile = (file) => {
    return new Promise((resolve) => {
      if (!file) {
        setBannerError('Please select a file.');
        setBannerPreview('');
        resolve('Please select a file.');
      } else if (file.size > 10 * 1024 * 1024) {
        setBannerError('File size should not exceed 10MB.');
        setBannerPreview('');
        resolve('File size should not exceed 10MB.');
      } else {
        const img = new Image();
        const url = URL.createObjectURL(file);
        img.onload = () => {
          if (img.width !== 1920 || img.height !== 600) {
            setBannerError('Image dimensions must be 1920 x 600 pixels.');
            setBannerPreview('');
            resolve('Image dimensions must be 1920 x 600 pixels.');
          } else {
            setBannerError('');
            setBannerPreview(url);
            resolve('');
          }
        };
        img.onerror = () => {
          setBannerError('Invalid image file.');
          setBannerPreview('');
          resolve('Invalid image file.');
        };
        img.src = url;
      }
    });
  };
  const handleMobileBannerChange = async (e) => {
    const file = e.target.files[0];
    await validateMobileBannerFile(file);
  };
  const validateMobileBannerFile = (file) => {
    return new Promise((resolve) => {
      if (!file) {
        setMobileBannerError('Please select a file.');
        setMobileBannerPreview('');
        resolve('Please select a file.');
      } else if (file.size > 10 * 1024 * 1024) {
        setMobileBannerError('File size should not exceed 10MB.');
        setMobileBannerPreview('');
        resolve('File size should not exceed 10MB.');
      } else {
        const img = new Image();
        const url = URL.createObjectURL(file);
        img.onload = () => {
          if (img.width !== 360 || img.height !== 477) {
            setMobileBannerError('Image dimensions must be 360 x 477 pixels.');
            setMobileBannerPreview('');
            resolve('Image dimensions must be 360 x 477 pixels.');
          } else {
            setMobileBannerError('');
            setMobileBannerPreview(url);
            resolve('');
          }
        };
        img.onerror = () => {
          setMobileBannerError('Invalid image file.');
          setMobileBannerPreview('');
          resolve('Invalid image file.');
        };
        img.src = url;
      }
    });
  };

  const handleBannerSubmit = async (event) => {
    event.preventDefault();
    const desktopBannerFile = InputRefDesktopBanner.current.files[0];
    const mobileBannerFile = InputRefMobileBanner.current.files[0];
    const desktopBannerFileError = await validateDesktopBannerFile(desktopBannerFile);
    const mobileBannerFileError = await validateMobileBannerFile(mobileBannerFile);
    setBannerError(desktopBannerFileError);
    setMobileBannerError(mobileBannerFileError);
    if (!desktopBannerFileError && !mobileBannerFileError) {


      try {
        const bannerData = new FormData();
        bannerData.append("company-logos", desktopBannerFile);
        const mobileBannerData = new FormData();
        mobileBannerData.append("company-logos", mobileBannerFile);
        setLoaded(false);
        const desktopBannerUploadResponse = await dispatch(uploadFile(bannerData));
        const mobileBannerUploadResponse = await dispatch(uploadFile(mobileBannerData));
        let obj = {
          desktopBannerPath: desktopBannerUploadResponse?.data?.data?.path,
          mobileBannerPath: mobileBannerUploadResponse?.data?.data?.path
        };


        await axiosApiInstance.post(`${BASE_URL}/api/bookEvent/uploadBookEventBanner`, obj);
        successAlert("Banner uploaded successfully");
        getAllBanners();
        setLoaded(true);
        setMobileBannerPreview('')
        setBannerPreview('');
        document.getElementById('bookEventBanner').value = '';
        document.getElementById('bookEventMobileBanner').value = '';
      } catch (error) {
        console.error("Banner upload failed:", error);
        setLoaded(true);
      }

    }

  };
  const setOpenBookEventModalFunc = (idsArr, title, id) => {
    setOpenBookEventModal(false);
    getAllCategories();

  };
  const OpenAddGameModal = (idsArr, title, id) => {
    setOpenBookEventModal(true);
    setGamesIdsForCategory(idsArr);
    setCategoryTitle(title);
    setCategoryId(id);
  };

  const handleDelete = async (id, path) => {

    const logo = [path];

    if (logo.length > 0) {
      setLoaded(false);
      try {
        await axiosApiInstance.post(`${BASE_URL}/api/file/deleteBanners`, logo);
        await axiosApiInstance.delete(`${BASE_URL}/api/bookEvent/delete/${id}`);
        getAllCategories();
        // dispatch(getAllOrganizationLogos(""));
        successAlert("Category deleted successfully");
        setLoaded(true);
      } catch (error) {
        failureAlert("Failed to delete ");
      } finally {
        setLoaded(true);
      }
    }
  };

  const deletePlatform =async (row)=>{
 
    const id=row.value;
    await axiosApiInstance.delete(`${BASE_URL}/api/bookEvent/deletePlatformData/${id}`);
    getAllPlatforms();
  }
  const deleteBanners = async (row) => {
    const { id, mobileBannerPath, bannerPath } = row;
    const banners = [mobileBannerPath, bannerPath];

    if (banners.length > 0) {
      setLoaded(false);
      try {
        await axiosApiInstance.post(BASE_URL + "/api/file/deleteBanners", banners);
        await axiosApiInstance.delete(`${BASE_URL}/api/bookEvent/deleteBanner/${id}`);
        await getAllBanners();
        successAlert("Banners deleted successfully");
      } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        failureAlert("Failed to delete banners");
      } finally {
        setLoaded(true);
      }
    }
  };



  const deleteBanner = async (id, path) => {
    const logo = [path];

    if (logo.length > 0) {
      setLoaded(false);
      try {
        await axiosApiInstance.post(`${BASE_URL}/api/file/deleteBanners`, logo);
        await axiosApiInstance.delete(`${BASE_URL}/api/bookEvent/deleteBanner/${id}`);
        getAllBanners();
        successAlert("Banner deleted successfully");
        setLoaded(true);
      } catch (error) {
        failureAlert("Failed to delete ");
      } finally {
        setLoaded(true);
      }
    }
  };
  const customStyles = {
    headCells: {
      style: {
        "font-family": "firaSans-semibold",
        "font-size": "15px",
        "border-bottom": "1px solid Black",
        "border-top": "1px solid Black"
      }
    },
    cells: {
      style: {
        "font-family": "firaSans-regular",
        "font-size": "14px",
        "text-align": "center"
      }
    },
    header: {
      style: {
        "font-family": "firaSans-semibold",
        color: "#737373"
      }
    }
  };
  const columns = [
    {
      name: "Sr No",
      selector: (row, index) => index + 1,
      center: true
    },
    {
      name: "Category Logo",
      selector: (row) => row.path,
      cell: row => {
        const imageUrl = `${S3_BASE_URL}${row.categoryImage}`;
        return <img src={imageUrl} style={{ width: '100px', height: '100px', padding: '10px' }} />;
      },
      center: true
    },
    {
      name: "Title",
      selector: (row) => row.title
    },
    {
      name: "Created Date",
      selector: (row) => new Date(row.createdAt).toLocaleDateString()
    },
    {
      name: "Actions",
      cell: row => (<>

        <button
          onClick={() => handleDelete(row.id, row.categoryImage)}
          className="btn btn-primary"
          style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
        >
          <img
            src={deleteIcon}
            alt="Delete"
            style={{ width: '20px', height: '20px' }}
          />
        </button>
      </>
      ),
      center: true
    }
  ];
  const bannerColumns = [
    {
      name: "Sr No",
      selector: (row, index) => index + 1,
      center: true
    },
    {
      name: "Desktop Banner",
      selector: (row) => row.path,
      cell: row => {
        const imageUrl = `${S3_BASE_URL}${row.bannerPath}`;
        return <img src={imageUrl} style={{ width: '300px', height: '100px', padding: '10px', borderRadius: '6px' }} />;
      },
      center: true
    },
    {
      name: "Mobile Banner",
      selector: (row) => row.path,
      cell: row => {
        const imageUrl = `${S3_BASE_URL}${row.mobileBannerPath}`;
        return <img src={imageUrl} style={{ width: '300px', height: '100px', padding: '10px', borderRadius: '6px' }} />;
      },
      center: true
    },
    {
      name: "Created Date",
      selector: (row) => new Date(row.createdAt).toLocaleDateString()
    },
    {
      name: "Actions",
      cell: row => (<>

        <button
          onClick={() => deleteBanners(row)}
          className="btn btn-primary"
          style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
        >
          <img
            src={deleteIcon}
            alt="Delete"
            style={{ width: '20px', height: '20px' }}
          />
        </button>
      </>
      ),
      center: true
    }
  ];
  const platformColumns = [
    {
      name: "Sr No",
      selector: (row, index) => index + 1,
      center: true
    },
   
    {
      name: "Title",
      selector: (row) =>row.label
    },
    {
      name: "Actions",
      cell: row => (<>

        <button
          onClick={() => deletePlatform(row)}
          className="btn btn-primary"
          style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
        >
          <img
            src={deleteIcon}
            alt="Delete"
            style={{ width: '20px', height: '20px' }}
          />
        </button>
      </>
      ),
      center: true
    }
  ];
  const handleMultiSelectChangePltforms = (options) => {

    setMultiSelectOptionsplatforms(options);
    setFormData((prevData) => ({
      ...prevData,
      supportedPlatforms: options
    }));
  };
  const handleMultiSelectChange = (options) => {

    setMultiSelectOptions(options);
    setFormData((prevData) => ({
      ...prevData,
      categories: options
    }));
  };
  const [rows, setRows] = useState([{ images: [null], errors: [''] }]);
  const [rowsOnEdit, setRowsOnEdit] = useState([{ images: [null], errors: [''] }]);
  const addRow = () => {
    setRows([...rows, { images: [null], errors: [''] }]);
  };
  const addRowOnEdit1 = () => {

    setRowsOnEdit([...rowsOnEdit, { images: [null], errors: [''] }]);
  };

  const validateImageDimensions = (file, expectedWidth, expectedHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const url = URL.createObjectURL(file);

      img.onload = () => {
        URL.revokeObjectURL(url);
        if (img.width === expectedWidth && img.height === expectedHeight) {
          resolve(true);
        } else {
          reject(`Image dimensions should be ${expectedWidth}x${expectedHeight}`);
        }
      };

      img.onerror = () => {
        URL.revokeObjectURL(url);
        reject('Error loading image');
      };

      img.src = url;
    });
  };

  const validateVideoDimensions = (file, expectedWidth, expectedHeight) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      const url = URL.createObjectURL(file);

      video.onloadedmetadata = () => {
        URL.revokeObjectURL(url);
        if (video.videoWidth === expectedWidth && video.videoHeight === expectedHeight) {
          resolve(true);
        } else {
          reject(`Video dimensions should be ${expectedWidth}x${expectedHeight}`);
        }
      };

      video.onerror = () => {
        URL.revokeObjectURL(url);
        reject('Error loading video');
      };

      video.src = url;
    });
  };

  const handleFileUpload = async (rowIndex, columnIndex, event) => {
    event.preventDefault();
    const file = event.target.files[0];
    event.target.value = null;

    if (file) {
      try {
        const expectedWidth = 400;
        const expectedHeight = 192;

        if (file.type.startsWith('image/')) {
          await validateImageDimensions(file, expectedWidth, expectedHeight);
        } else if (file.type === 'video/mp4') {
          await validateVideoDimensions(file, expectedWidth, expectedHeight);
        }


        setRows(rows.map((row, index) =>
          index === rowIndex
            ? { ...row, images: row.images.map((img, i) => i === columnIndex ? file : img) }
            : row
        ));
      } catch (error) {

        setRows(rows.map((row, index) =>
          index === rowIndex
            ? { ...row, errors: row.errors.map((err, i) => i === columnIndex ? error : err) }
            : row
        ));
      }
    }
  };



  useEffect(() => {
    const newRowsFaq = faqsData.map(item => ({
      title: item.title,
      description: item.description,
      errors: { title: '', description: '' }
    }));

    setRowsFaq(newRowsFaq);
  }, [faqsData]);

  useEffect(() => {
    const newTestimonialRows = testimonialData.map(item => ({
      id: item.id,
      image: item.testimonialImagePath,
      name: item.name,
      designation: item.designation,
      comment: item.comment,
      errors: { image: '', name: '', designation: '', comment: '' }
    }));

    setTestimonialRows(newTestimonialRows);
  }, [testimonialData]);

  const updateBannerPaths = (bannersArray, rowIndex, colIndex, newPath) => {
    // Check if rowIndex is greater than the current array length
    if (rowIndex >= bannersArray.length) {
      // Add new banner objects until reaching rowIndex
      for (let i = bannersArray.length; i <= rowIndex; i++) {
        bannersArray.push({
          mobileBannerPath: '',
          desktopBannerPath: ''
        });
      }
    }

    if (colIndex === 0) {
      bannersArray[rowIndex].mobileBannerPath = newPath;
    } else if (colIndex === 1) {
      bannersArray[rowIndex].desktopBannerPath = newPath;
    }
    return bannersArray;
  };

  const handleFileUploadOnEdit = async (rowIndex, columnIndex, event) => {
    event.preventDefault();
    const file = event.target.files[0];
    event.target.value = null;

    if (file) {
      try {
        const expectedWidth = 400;
        const expectedHeight = 192;

        if (file.type.startsWith('image/')) {
          await validateImageDimensions(file, expectedWidth, expectedHeight);
        } else if (file.type === 'video/mp4') {
          await validateVideoDimensions(file, expectedWidth, expectedHeight);
        }


        const result = await uploadImage(file);
        const foundObject = AllGames.find(item => item.id === editGameId);
        const updatedBanners = updateBannerPaths(foundObject?.images, rowIndex, columnIndex, result);

        const obj = {
          gameId: editGameId,
          pathsArr: updatedBanners
        }
        await axiosApiInstance.post(BASE_URL + "/api/bookEvent/updateGamesImages", obj)
        await getAllGamesImages(editGameId);

      } catch (error) {

        setRows(rows.map((row, index) =>
          index === rowIndex
            ? { ...row, errors: row.errors.map((err, i) => i === columnIndex ? error : err) }
            : row
        ));
      }
    }
  };
  const removeRow = (rowIndex) => {
    setRows(rows.filter((_, index) => index !== rowIndex));
  };
  const uploadImage = async (file) => {
    const data = new FormData();
    data.append("banner-images", file);

    try {
      const response = await dispatch(uploadFile(data));
      return response.data.data.path;
    } catch (error) {
      console.error('Upload failed:', error);
      return null;
    }
  };
  const uploadVideo = async (file) => {
    const data = new FormData();
    data.append("videobanners", file);

    try {
      const response = await dispatch(uploadFile(data));
      return response.data.data.path;
    } catch (error) {
      console.error('Upload failed:', error);
      return null;
    }
  };


  const [rowsFaq, setRowsFaq] = useState([
    { title: '', description: '', errors: { title: '', description: '' } },
  ]);

  const handleInputChange = (rowIndex, field, value) => {
    const updatedRows = rowsFaq.map((row, i) => {
      if (i === rowIndex) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setRowsFaq(updatedRows);
  };

  const validateRowsFaq = () => {
    let isValid = true;
    const updatedRows = rowsFaq.map((row) => {
      const errors = { title: '', description: '' };

      if (!row.title.trim()) {
        errors.title = 'Title is required';
        isValid = false;
      }

      if (!row.description.trim()) {
        errors.description = 'Description is required';
        isValid = false;
      }

      return { ...row, errors };
    });

    setRowsFaq(updatedRows);
    return isValid;
  };

  const addRowFaq = () => {
    setRowsFaq([
      ...rowsFaq,
      { title: '', description: '', errors: { title: '', description: '' } },
    ]);
  };

  const removeRowFaq = (index) => {
    const updatedRows = rowsFaq.filter((_, i) => i !== index);
    setRowsFaq(updatedRows);
  };

  const [testimonialRows, setTestimonialRows] = useState([
    {
      image: null,
      name: '',
      designation: '',
      comment: '',
      errors: { image: '', name: '', designation: '', comment: '' },
    },
  ]);
  const [gamesRows, setGamesRows] = useState([
    {
      image: null,
      errors: { image: '' },
    },
  ]);
  const [mainGamesRows, setMainGamesRows] = useState([
    {
      image: null,
      errors: { image: '' },
    },
  ]);
  const handleTestimonialFileUpload = (rowIndex, event) => {
    const file = event.target.files[0];

    if (file) {

      const MAX_SIZE_MB = 2;
      const MAX_SIZE = MAX_SIZE_MB * 1024 * 1024;

      const updatedRows = [...testimonialRows];


      updatedRows[rowIndex].errors.image = '';


      if (file.size > MAX_SIZE) {
        updatedRows[rowIndex].errors.image = `File size must be less than ${MAX_SIZE_MB}MB.`;
        setTestimonialRows(updatedRows);
        event.target.value = '';
        return;
      }

      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {

        if (img.width !== 300 || img.height !== 300) {
          updatedRows[rowIndex].errors.image = 'Image must be 300x300 pixels.';
          setTestimonialRows(updatedRows);
          event.target.value = '';
        } else {

          updatedRows[rowIndex].image = file;
          setTestimonialRows(updatedRows);
        }
      };

      img.onerror = () => {
        updatedRows[rowIndex].errors.image = 'Invalid image file.';
        setTestimonialRows(updatedRows);
        event.target.value = '';
      };
    } else {

      event.target.value = '';
    }
  };


  const handleGamesFileUpload = (rowIndex, event) => {
    const file = event.target.files[0];

    if (file) {
      const MAX_SIZE_MB = 10;
      const MAX_SIZE = MAX_SIZE_MB * 1024 * 1024;

      const updatedRows = [...gamesRows];
      updatedRows[rowIndex].errors.image = '';

      // Validate file size
      if (file.size > MAX_SIZE) {
        updatedRows[rowIndex].errors.image = `File size must be less than ${MAX_SIZE_MB}MB.`;
        setGamesRows(updatedRows);
        event.target.value = '';
        return;
      }


      if (file.type.includes('image')) {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          if (img.width !== 500 || img.height !== 450) {
            updatedRows[rowIndex].errors.image = 'Image must be 500x450 pixels.';
            setGamesRows(updatedRows);
            event.target.value = '';
          } else {
            updatedRows[rowIndex].image = file;
            setGamesRows(updatedRows);
          }
        };

        img.onerror = () => {
          updatedRows[rowIndex].errors.image = 'Invalid image file.';
          setGamesRows(updatedRows);
          event.target.value = '';
        };
      }

      else if (file.type.includes('video')) {
        const video = document.createElement('video');
        video.src = URL.createObjectURL(file);
        video.onloadedmetadata = () => {

          const validWidth = 500;
          const validHeight = 450;

          if (video.videoWidth !== validWidth || video.videoHeight !== validHeight) {
            updatedRows[rowIndex].errors.image = `Video must be ${validWidth}x${validHeight} pixels.`;
            setGamesRows(updatedRows);
            event.target.value = '';
          } else {
            updatedRows[rowIndex].image = file;
            setGamesRows(updatedRows);
          }
        };

        video.onerror = () => {
          updatedRows[rowIndex].errors.image = 'Invalid video file.';
          setGamesRows(updatedRows);
          event.target.value = '';
        };
      }
    } else {
      event.target.value = '';
    }
  };

  const handleMainGamesFileUpload = (rowIndex, event) => {
    const file = event.target.files[0];

    if (file) {
      const MAX_SIZE_MB = 10;
      const MAX_SIZE = MAX_SIZE_MB * 1024 * 1024;

      const updatedRows = [...mainGamesRows];
      updatedRows[rowIndex].errors.image = '';

      // Validate file size
      if (file.size > MAX_SIZE) {
        updatedRows[rowIndex].errors.image = `File size must be less than ${MAX_SIZE_MB}MB.`;
        setMainGamesRows(updatedRows);
        event.target.value = '';
        return;
      }

      // Handle image files (validate dimensions)
      if (file.type.includes('image')) {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          if (img.width !== 850 || img.height !== 950) {
            updatedRows[rowIndex].errors.image = 'Image must be 850x950 pixels.';
            setMainGamesRows(updatedRows);
            event.target.value = '';
          } else {
            updatedRows[rowIndex].image = file;
            setMainGamesRows(updatedRows);
          }
        };

        img.onerror = () => {
          updatedRows[rowIndex].errors.image = 'Invalid image file.';
          setMainGamesRows(updatedRows);
          event.target.value = '';
        };
      }
      // Handle video files (validate dimensions)
      else if (file.type.includes('video')) {
        const video = document.createElement('video');
        video.src = URL.createObjectURL(file);
        video.onloadedmetadata = () => {
          // Define acceptable video dimensions (e.g., 1280x720)
          const validWidth = 850;
          const validHeight = 950;

          if (video.videoWidth !== validWidth || video.videoHeight !== validHeight) {
            updatedRows[rowIndex].errors.image = `Video must be ${validWidth}x${validHeight} pixels.`;
            setMainGamesRows(updatedRows);
            event.target.value = '';
          } else {
            updatedRows[rowIndex].image = file;
            setMainGamesRows(updatedRows);
          }
        };

        video.onerror = () => {
          updatedRows[rowIndex].errors.image = 'Invalid video file.';
          setMainGamesRows(updatedRows);
          event.target.value = '';
        };
      }
    } else {
      event.target.value = '';
    }
  };


  const handleGamesFileUploadOnEdit = (rowIndex, event) => {
    const file = event.target.files[0];

    if (file) {
      const MAX_SIZE_MB = 10;
      const MAX_SIZE = MAX_SIZE_MB * 1024 * 1024;

      const updatedRows = [...gamesRows];
      updatedRows[rowIndex].errors.image = '';

      // Validate file size
      if (file.size > MAX_SIZE) {
        updatedRows[rowIndex].errors.image = `File size must be less than ${MAX_SIZE_MB}MB.`;
        setGamesRows(updatedRows);
        event.target.value = '';
        return;
      }

      // Check if it's an image
      if (file.type.startsWith('image/')) {
        const img = new Image();
        const objectURL = URL.createObjectURL(file);

        img.src = objectURL;
        img.onload = async () => {
          if (img.width !== 500 || img.height !== 450) {
            updatedRows[rowIndex].errors.image = 'Image must be 500x450 pixels.';
            setGamesRows(updatedRows);
            event.target.value = '';
          } else {
            const r = await uploadImage(file); // Upload the image
            const obj = { gameId: editGameId, path: r };
            await axiosApiInstance.post(BASE_URL + "/api/bookEvent/updateGamesImages", obj);
            await getAllGamesImages(editGameId);
          }
        };

        img.onerror = () => {
          updatedRows[rowIndex].errors.image = 'Invalid image file.';
          setGamesRows(updatedRows);
          event.target.value = '';
        };

        // Check if it's a video
      } else if (file.type.startsWith('video/')) {
        const video = document.createElement('video');
        const objectURL = URL.createObjectURL(file);

        video.src = objectURL;
        video.onloadedmetadata = async () => {
          const validWidth = 500;
          const validHeight = 450;

          if (video.videoWidth !== validWidth || video.videoHeight !== validHeight) {
            updatedRows[rowIndex].errors.image = `Video must be ${validWidth}x${validHeight} pixels.`;
            setGamesRows(updatedRows);
            event.target.value = '';
          } else {
            const r = await uploadImage(file); // Upload the video
            const obj = { gameId: editGameId, path: r };
            await axiosApiInstance.post(BASE_URL + "/api/bookEvent/updateGamesImages", obj);
            await getAllGamesImages(editGameId);
          }
        };

        video.onerror = () => {
          updatedRows[rowIndex].errors.image = 'Invalid video file.';
          setGamesRows(updatedRows);
          event.target.value = '';
        };
      } else {
        updatedRows[rowIndex].errors.image = 'Unsupported file type. Please upload an image or video.';
        setGamesRows(updatedRows);
      }
    } else {
      event.target.value = '';
    }
  };

  const handleMainFileUploadOnEdit = (rowIndex, event) => {
    const file = event.target.files[0];

    if (file) {
      const MAX_SIZE_MB = 10;
      const MAX_SIZE = MAX_SIZE_MB * 1024 * 1024;

      const updatedRows = [...mainGamesRows];
      updatedRows[rowIndex].errors.image = '';

      // Validate file size
      if (file.size > MAX_SIZE) {
        updatedRows[rowIndex].errors.image = `File size must be less than ${MAX_SIZE_MB}MB.`;
        setMainGamesRows(updatedRows);
        event.target.value = '';
        return;
      }

      // Check if it's an image
      if (file.type.startsWith('image/')) {
        const img = new Image();
        const objectURL = URL.createObjectURL(file);

        img.src = objectURL;
        img.onload = async () => {
          if (img.width !== 850 || img.height !== 950) {
            updatedRows[rowIndex].errors.image = 'Image must be 850x950 pixels.';
            setMainGamesRows(updatedRows);
            event.target.value = '';
          } else {
            const r = await uploadImage(file); // Upload the image
            const obj = { gameId: editGameId, path: r };
            await axiosApiInstance.post(BASE_URL + "/api/bookEvent/updateMainImages", obj)
            await getAllMainImages(editGameId);

          }
        };

        img.onerror = () => {
          updatedRows[rowIndex].errors.image = 'Invalid image file.';
          setMainGamesRows(updatedRows);
          event.target.value = '';
        };

        // Check if it's a video
      } else if (file.type.startsWith('video/')) {
        const video = document.createElement('video');
        const objectURL = URL.createObjectURL(file);

        video.src = objectURL;
        video.onloadedmetadata = async () => {
          const validWidth = 850;
          const validHeight = 950;

          if (video.videoWidth !== validWidth || video.videoHeight !== validHeight) {
            updatedRows[rowIndex].errors.image = `Video must be ${validWidth}x${validHeight} pixels.`;
            setMainGamesRows(updatedRows);
            event.target.value = '';
          } else {
            const r = await uploadImage(file); // Upload the video
            const obj = { gameId: editGameId, path: r };
            await axiosApiInstance.post(BASE_URL + "/api/bookEvent/updateMainImages", obj)
            await getAllMainImages(editGameId);

          }
        };

        video.onerror = () => {
          updatedRows[rowIndex].errors.image = 'Invalid video file.';
          setGamesRows(updatedRows);
          event.target.value = '';
        };
      } else {
        updatedRows[rowIndex].errors.image = 'Unsupported file type. Please upload an image or video.';
        setGamesRows(updatedRows);
      }
    } else {
      event.target.value = '';
    }
  };

  const handleTestimonialInputChange = (rowIndex, field, value) => {
    const updatedRows = testimonialRows.map((row, i) => {
      if (i === rowIndex) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setTestimonialRows(updatedRows);
  };


  const addTestimonialRow = (event) => {
    event.preventDefault();
    setTestimonialRows([
      ...testimonialRows,
      { image: null, name: '', designation: '', comment: '', errors: { image: '', name: '', designation: '', comment: '' } },
    ]);
  };

  const addGamesRow = () => {
    setGamesRows([
      ...gamesRows,
      { image: null, errors: { image: '' } },
    ]);
  };

  const addMainGamesRow = () => {
    setMainGamesRows([
      ...mainGamesRows,
      { image: null, errors: { image: '' } },
    ]);
  };
  const removeTestimonialRow = async (rowIndex, row) => {
console.log(rowIndex,row,"@@@")


if(row && row.hasOwnProperty('id')){
     const pathsArray = [
        row.image
      ];
      await axiosApiInstance.post(BASE_URL + "/api/file/deleteBanners", pathsArray);
      await axiosApiInstance.delete(`${BASE_URL}/api/bookEvent/deleteTestimonialOnId/${row.id}`);
      const dataTestimonial = await fetchTestimonialOnGameId(editGameId);
      setTestimonialData(dataTestimonial);
}
else{
  const updatedRows = testimonialRows.filter((_, i) => i !== rowIndex);
    setTestimonialRows(updatedRows);

}
  
 

  };


  const removeGamesRow = async (rowIndex, row) => {

    if ((row && row.hasOwnProperty('image')) || !row) {
      const updatedRows = gamesRows.filter((_, i) => i !== rowIndex);
      setGamesRows(updatedRows);
    }
    else {
      const pathsArray = [
        row.images[1]
      ];
      await axiosApiInstance.post(BASE_URL + "/api/file/deleteBanners", pathsArray);
      await axiosApiInstance.delete(`${BASE_URL}/api/bookEvent/deleteGamesImages/${row.images[0]}`);
      await getAllGamesImages(editGameId);
    }
  };
  const removeMainRow = async (rowIndex, row) => {

    if ((row && row.hasOwnProperty('image')) || !row) {
      const updatedRows = mainGamesRows.filter((_, i) => i !== rowIndex);
      setMainGamesRows(updatedRows);
    }
    else {
      const pathsArray = [
        row.images[1]
      ];
      await axiosApiInstance.post(BASE_URL + "/api/file/deleteBanners", pathsArray);
      await axiosApiInstance.delete(`${BASE_URL}/api/bookEvent/deleteMainImages/${row.images[0]}`);
      await getAllMainImages(editGameId);
    }
  };




  const validateFormTestimonail = () => {
    let isValid = true;
    const updatedRows = testimonialRows.map((row) => {
      const errors = { image: '', name: '', designation: '', comment: '' };

      if (!row.image) {
        errors.image = 'Image is required';
        isValid = false;
      }
      if (!row.name.trim()) {
        errors.name = 'Name is required';
        isValid = false;
      }
      if (!row.designation.trim()) {
        errors.designation = 'Designation is required';
        isValid = false;
      }
      if (!row.comment.trim()) {
        errors.comment = 'Comment is required';
        isValid = false;
      }

      return { ...row, errors };
    });

    setTestimonialRows(updatedRows);
    return isValid;
  };
  const validateGamesImages = () => {
    let isValid = true;
    const updatedRows = gamesRows.map((row) => {
      const errors = { image: '' };

      if (!row.image) {
        errors.image = 'Image is required';
        isValid = false;
      }



      return { ...row, errors };
    });

    setGamesRows(updatedRows);
    return isValid;
  };

  const validateMainGamesImages = () => {
    let isValid = true;
    const updatedRows = mainGamesRows.map((row) => {
      const errors = { image: '' };

      if (!row.image) {
        errors.image = 'Image is required';
        isValid = false;
      }
      return { ...row, errors };
    });

    setMainGamesRows(updatedRows);
    return isValid;
  };
  //
  const AddNewGame = async (e) => {
    e.preventDefault();
    const faqArrWithNonEmptyValues = rowsFaq
      .filter(item => item.title && item.description)  // Filter out items with empty title or description
      .map(item => {
        // Remove empty values from the final object
        return {
          title: item.title,
          description: item.description,
          errors: Object.fromEntries(
            Object.entries(item.errors).filter(([key, value]) => value) // Filter out empty errors
          )
        };
      });


    const filteredTestimonials = testimonialRows.filter(item =>
      item.image && item.name && item.designation && item.comment
    );


    if (isEditing) {
      if (validateForm()) {
        formData.gameBanner = GameBannerpathOnEdit;
        setLoaded(false);
        const objectsWithoutId = filteredTestimonials.filter(testimonial => !testimonial.hasOwnProperty('id'));
        const testimonialPaths = objectsWithoutId.map(async (row) => {
          const [file1Path] = await Promise.all([
            row.image ? (row.image.type.startsWith('image/') ? uploadImage(row.image) : uploadVideo(row.image)) : null
          ]);
          return { path: file1Path };
        });
        const paths = await Promise.all(testimonialPaths);
        let pathIndex = 0;
        const finalArray = filteredTestimonials.map((item) => {
          const isIdPresent = !!item.id;
          const assignedPath = isIdPresent ? item.image : (paths[pathIndex]?.path || null);
          if (!isIdPresent) {
            pathIndex++;
          }
          return {
            id: isIdPresent ? item.id : null,
            name: item.name,
            designation: item.designation,
            comment: item.comment,
            path: assignedPath
          };
        });
        const obj = {
          formvalues: formData,
          faqs: faqArrWithNonEmptyValues,
          testimonials: finalArray,
          gameid: editGameId,
          categoriesIDS: multiSelectOptions,
          platformIDS: multiSelectOptionsPlatforms
        }
       
        await axiosApiInstance.put(BASE_URL + "/api/bookEvent/updateGame", obj)
          .then(async (response) => {

            successAlert('Games Updated Successfully')
            getAllGames();
            setLoaded(true);
            setIsNewGame(!isNewGame);
            setIsEditing(false);
            setFormData(initialFormData);
            setId(null);
            setMultiSelectOptions([]);
            getAllGames();
          })
          .catch((error) => {
            failureAlert("Failed to add request");
            setLoaded(true);
          });
      }
    }
    else {
      const file = fileInputRefGameBanner.current.files[0];
      const fileError = await validateGameBannerFile(file);
      setGameBannerFileError(fileError);
      if (validateForm() && validateGamesImages() && validateMainGamesImages() && !fileError) {
        const mainGamesData = mainGamesRows.map((row) => ({
          image: row.image
        }));
        const gamesRowsdata = gamesRows.map((row) => ({
          image: row.image
        }));
        const testimonaildata = filteredTestimonials.map((row) => ({
          image: row.image,
          name: row.name,
          designation: row.designation,
          comment: row.comment,
        }));
        formData.Faq = faqArrWithNonEmptyValues || [];
        try {
          setLoaded(false);
          const GameBannerpath = await uploadImage(file);
          formData.gameBanner = GameBannerpath;
          const maingamesPath = mainGamesData.map(async (row) => {
            const [file1Path] = await Promise.all([
              row.image ? (row.image.type.startsWith('image/') ? uploadImage(row.image) : uploadVideo(row.image)) : null
            ]);
            return { path: file1Path };
          });

          const mainpathsgames = await Promise.all(maingamesPath);
          const finalArraymainGamespath = mainpathsgames.map((img, index) => ({
            path: img.path,

          }));
          formData.maingamespath = finalArraymainGamespath;
          /////
          const gamesPath = gamesRowsdata.map(async (row) => {
            const [file1Path] = await Promise.all([
              row.image ? (row.image.type.startsWith('image/') ? uploadImage(row.image) : uploadVideo(row.image)) : null
            ]);
            return { path: file1Path };
          });

          const pathsgames = await Promise.all(gamesPath);
          const finalArrayGamespath = pathsgames.map((img, index) => ({
            path: img.path,

          }));
          formData.gamespath = finalArrayGamespath;

          let finalArray;
          if (testimonaildata.length > 0) {
            const testimonialPaths = testimonaildata.map(async (row) => {
              const [file1Path] = await Promise.all([
                row.image ? (row.image.type.startsWith('image/') ? uploadImage(row.image) : uploadVideo(row.image)) : null
              ]);
              return { path: file1Path };
            });

            const paths = await Promise.all(testimonialPaths);

            finalArray = paths.map((img, index) => ({
              path: img.path,
              name: testimonaildata[index].name,
              designation: testimonaildata[index].designation,
              comment: testimonaildata[index].comment
            }));
          }


          formData.testimonials = finalArray || [];


          await axiosApiInstance.post(BASE_URL + "/api/bookEvent/addGame", formData)
            .then(async (response) => {
              successAlert("Game added successfully");
              setFormData(initialFormData);
              setFile1(null);
              setFile2(null);
              setMultiSelectOptions([]);
              setRows([{ images: [null, null], errors: ['', ''] }]);
              setMainGamesRows([{ images: [null, null], errors: ['', ''] }]);
              setGamesRows([{ images: [null, null], errors: ['', ''] }]);
              setRowsFaq([
                { title: '', description: '', errors: { title: '', description: '' } },
              ]);
              setTestimonialRows([
                {
                  image: null,
                  name: '',
                  designation: '',
                  comment: '',
                  errors: { image: '', name: '', designation: '', comment: '' },
                },
              ]);
              setLoaded(true);
              getAllGames();
            })
            .catch((error) => {

              failureAlert("Failed to add request");
              setLoaded(true);
            });


        } catch (error) {
          console.error("Error during file uploads:", error);
        }
      }


    }
  };


  const [isNewGame, setIsNewGame] = useState(true);
  const [editGameId, setId] = useState(null);
  const toggleText = () => {
    setShowTestimonials(false);
    setShowFaqs(false);
    setIsNewGame(!isNewGame);
    setIsEditing(false);
    setFormData(initialFormData);
    setId(null);
    setMultiSelectOptions([]);
    setMultiSelectOptionsplatforms([]);
    setGamesRows([{ image: null, errors: { image: '' } }]);
    setTestimonialRows([
      {
        image: null,
        name: '',
        designation: '',
        comment: '',
        errors: { image: '', name: '', designation: '', comment: '' },
      },
    ]);
  };
  const deleteGame = async (obj) => {

    if (obj.images.length > 0) {
      const bannerPaths = obj.images.flatMap(image => [
        image.desktopBannerPath,
        // image.mobileBannerPath
      ]);
      if (bannerPaths.length > 0) {
        setLoaded(false);
        try {
          await axiosApiInstance.post(BASE_URL + "/api/file/deleteBanners", bannerPaths);

        } catch (error) {
          console.error('Error:', error.response ? error.response.data : error.message);
          failureAlert("Failed to delete banner");
        } finally {
          setLoaded(true);
        }
      }

    }
    if (obj.testimonials.length > 0) {
      const testimonialPaths = obj.testimonials.flatMap(image => [
        image.testimonialImagePath

      ]);
      if (testimonialPaths.length > 0) {
        setLoaded(false);
        try {
          await axiosApiInstance.post(BASE_URL + "/api/file/deleteBanners", testimonialPaths);

        } catch (error) {
          console.error('Error:', error.response ? error.response.data : error.message);
          failureAlert("Failed to delete banner");
        } finally {
          setLoaded(true);
        }
      }

    }
    try {
      setLoaded(false);
      await axiosApiInstance.delete(`${BASE_URL}/api/bookEvent/deleteGame/${obj.id}`);
      successAlert('Game Deleted Successfully');
      getAllGames();
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      failureAlert("Failed to delete game");
    } finally {
      setLoaded(true);
    }

  }
  const [isEditing, setIsEditing] = useState(false);
  async function fetchFaqOnGameId(id) {
    try {
      setLoaded(false); // Optionally set a loading state
      const response = await axiosApiInstance.get(`${BASE_URL}/api/bookEvent/getFaqOnGameId/${id}`);

      return response.data.data; // Return the game data
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      failureAlert("Failed to fetch game data");
    } finally {
      setLoaded(true); // Optionally reset the loading state
    }
  }
  async function fetchTestimonialOnGameId(id) {
    try {
      setLoaded(false); // Optionally set a loading state
      const response = await axiosApiInstance.get(`${BASE_URL}/api/bookEvent/getTestimonialOnGameId/${id}`);

      return response.data.data; // Return the game data
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      failureAlert("Failed to fetch game data");
    } finally {
      setLoaded(true); // Optionally reset the loading state
    }
  }


  async function getGameInfo(id) {
    try {
      setLoaded(false);
      const response = await axiosApiInstance.get(`${BASE_URL}/api/bookEvent/getGameInfo/${id}`);

      return response.data.data; // Return the game data
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      failureAlert("Failed to fetch game000 data");
    } finally {
      setLoaded(true); // Optionally reset the loading state
    }
  }
  async function getAllPlatforms() {
    try {
      setLoaded(false);
      const response = await axiosApiInstance.get(`${BASE_URL}/api/bookEvent/getAllPlatforms`);


const result = response.data.data;

const options = result.map(item => ({
  value: item.id,
  label: item.name,
}));

setBookEventPlatforms(options);
      //return response.data.data; // Return the game data
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      failureAlert("Failed to fetch game000 data");
    } finally {
      setLoaded(true); // Optionally reset the loading state
    }
  }


  const editGame = async (gameobjInfo) => {
  
    setMultiSelectOptions([]);

    setMultiSelectOptionsplatforms([])
    setId(gameobjInfo?.id)

    const dataFaq = await fetchFaqOnGameId(gameobjInfo?.id);

    if (dataFaq.length > 0) {
      setShowFaqs(true);
      setfaqsData(dataFaq);
    }


    const dataTestimonial = await fetchTestimonialOnGameId(gameobjInfo?.id);

    if (dataTestimonial.length > 0) {
      setShowTestimonials(true);
      setTestimonialData(dataTestimonial);
    }


    await getAllGamesImages(gameobjInfo?.id);

    await getAllMainImages(gameobjInfo?.id);

    setIsEditing(true);
    setIsNewGame(!isNewGame);

    const gameInfo = await getGameInfo(gameobjInfo?.id);
  
    setGameBannerPathOnEdit(gameInfo?.gameBanner);
    setGameBannerPreview(gameInfo?.gameBanner);
    const seldata = gameInfo?.categories.map(category => ({
      value: category.id,
      label: category.title
    }));

    setMultiSelectOptions(seldata);



    ////////////



    const selplatforms = gameInfo?.platforms.map(category => ({
      value: category.id,
      label: category.name
    }));

    setMultiSelectOptionsplatforms(selplatforms);


    // setMultiSelectOptionsplatforms(gameInfo?.selectedPlatforms)
    setFormData(prevValues => ({
      ...prevValues,
      title: gameInfo?.title,
      description: gameInfo?.description,
      feature: gameInfo?.feature,
      minPlayer: gameInfo?.minPlayers,
      maxPlayer: gameInfo?.maxPlayers,
      duration: gameInfo?.duration,
      eventCost: gameInfo?.costOfEvent?.INR,
      eventCostUSD: gameInfo?.costOfEvent?.USD,
      eventType: gameInfo?.typeOfEvent,
      categories: bookEventCategories,
      difficultylevel: gameInfo?.levelOfDifficulty,
      gameType: gameInfo?.gameType,
      bookingType: gameInfo?.bookingType,
      guaranteeType: gameInfo?.guaranteeType,
      headCount: gameInfo?.headCount,
      howItWorks: gameInfo?.howItWorks,
      agenda: gameInfo?.agenda,
      thingsYouNeed: gameInfo?.thingsYouWillNeed,
      // howToJoin: gameInfo?.howToJoin,
      breakOutRoom: gameInfo?.isBreakOutRoom,
      breakOutRoomSize: gameInfo?.breakOutRoomSize,
      supportedPlatforms: bookEventplatforms
    }));


  };

  const removeRowFromDb = async (rowIndex, img2) => {

    if (img2) {
      const imagesForId = getImagesById(editGameId);

      const pathsArray = [
        imagesForId[rowIndex].desktopBannerPath,

      ];
      if (pathsArray.length > 0) {
        setLoaded(false);
        try {
          await axiosApiInstance.post(BASE_URL + "/api/file/deleteBanners", pathsArray);
          await axiosApiInstance.delete(`${BASE_URL}/api/bookEvent/deleteGamesImages/${imagesForId[rowIndex].id}`);
          await getAllGamesImages(editGameId);
          successAlert('Data Deleted');
        } catch (error) {
          console.error('Error:', error.response ? error.response.data : error.message);
          failureAlert("Failed to delete banner");
        } finally {
          setLoaded(true);
        }
      }
    }
    else {

      setRowsOnEdit(rowsOnEdit.filter((_, index) => index !== rowIndex));

    }



  }
  function getImagesById(gameId) {
    const game = AllGames.find(game => game.id === gameId);
    return game ? game.images : [];
  }

  const [platformName, setPlatformName] = useState('');
  
    const [platformNameerror, setplatformNameError] = useState('');

    const handlePlatformNameChange = (event) => {
        setPlatformName(event.target.value);
        setplatformNameError(''); // Clear error on input change
    };

    const submitPlatformData = async(event) => {
        event.preventDefault();
        if (platformName.trim() === '') {
          setplatformNameError('Platform name is required');
        } else {
        
         let obj = { name: platformName };
         const result = await axiosApiInstance
           .post(BASE_URL + "/api/bookEvent/addPlatform", obj)
           .then((response) => {
            setPlatformName('');

            //  successAlert("Dates updated successfully");
             getAllPlatforms();
            //  setLoaded(true);
           })
           .catch((error) => {
             failureAlert("Failed to add request");
             setLoaded(true);
           });
           // setPlatformName(''); // Clear the input field
        }
    };
    const [showTestimonials, setShowTestimonials] = useState(false);
    const [showFaqs, setShowFaqs] = useState(false);

    const handleTestimonialChange = () => {
        setShowTestimonials(prevState => !prevState);
    };
  
    const handleFaqChange = () => {
        setShowFaqs(prevState => !prevState);
    };
  return (
    <div className="admin-homepage">
      <ToastContainer position='bottom-center' />
      <LoadingComponent loaded={loaded} />
      {
        openBookEventModal &&
        <AddGamesBookEvent modalid={"demo"} toggle={openBookEventModal} categoryTitle={categoryTitle} idsArr={idsArr} categoryId={categoryId} setOpenBookEventModal={setOpenBookEventModalFunc} />
      }
      <PageLayout
        sidebartitle=""
        active={"Book An Event"}
        category
        sideBarContents={sidebarContentAdmin}
        profile
        signOut={signOut}
        isAdmin={isAdmin}
        {...props}
      >
        <div className="container-payment ">
          <div className="payment">
            <h1 className="title">Book event</h1>
          </div>
        </div>

        <ToastContainer position="bottom-center" />

        <div style={{ textAlign: 'center', marginTop: '20px' }}>

          <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
            <button
              onClick={() => handleTabClick('Categories')}
              style={{
                padding: '10px 20px',
                backgroundColor: activeTab === 'Categories' ? '#ea6c00' : '#f0f0f0',
                color: activeTab === 'Categories' ? '#fff' : '#000',
                borderRadius: '5px',
                cursor: 'pointer',
                border: 'none'
              }}
            >
              Categories
            </button>
            <button
              onClick={() => handleTabClick('Games')}
              style={{
                padding: '10px 20px',
                backgroundColor: activeTab === 'Games' ? '#ea6c00' : '#f0f0f0',
                color: activeTab === 'Games' ? '#fff' : '#000',
                borderRadius: '5px',
                cursor: 'pointer',
                border: 'none'
              }}
            >
              Games
            </button>
            <button
              onClick={() => handleTabClick('Testimonials')}
              style={{
                padding: '10px 20px',
                backgroundColor: activeTab === 'Testimonials' ? '#ea6c00' : '#f0f0f0',
                color: activeTab === 'Testimonials' ? '#fff' : '#000',
                borderRadius: '5px',
                cursor: 'pointer',
                border: 'none'
              }}
            >
              Banners
            </button>
            <button
              onClick={() => handleTabClick('platforms')}
              style={{
                padding: '10px 20px',
                backgroundColor: activeTab === 'platforms' ? '#ea6c00' : '#f0f0f0',
                color: activeTab === 'platforms' ? '#fff' : '#000',
                borderRadius: '5px',
                cursor: 'pointer',
                border: 'none'
              }}
            >
              Platforms Allowed
            </button>
          </div>


          <div style={{ marginTop: '30px' }}>
            {activeTab === 'Categories' && (
              <div className="flex justify-between gap-5">
                <div className="w-full">
                  <DataTable
                    title="Book Event Categories"
                    columns={columns}
                    data={categories}
                    className="rdt_TableHead"
                    responsive
                    striped
                    highlightOnHover
                    pagination
                    customStyles={customStyles}
                  />

                </div>

                <div className="w-1/2">
                  <form className="book-event-category-form" onSubmit={handleSubmitForm}>
                    <h3 className="text-lg font-bold mb-4">Add Category</h3>
                    <div className="form-group mb-4">
                      <label htmlFor="categoryName" className="block font-bold mb-2">Category Name</label>
                      <input
                        type="text"
                        id="categoryName"
                        name="categoryName"
                        value={categoryName}
                        onChange={handlecategoryNameChange}
                        placeholder="Enter "
                        className="w-full p-2 border border-gray-300 rounded"
                      />
                      {categoryNameError && <div className="errormessage text-red-500 mt-2">{categoryNameError}</div>}
                    </div>

                    <div className="form-group mb-4">
                      <label htmlFor="logo" className="block font-bold mb-2">Category Image</label>
                      <label htmlFor="logo" className="text-gray-500">Size: 150 x 150 px</label>
                      <input
                        type="file"
                        id="categoryImage"
                        name="logo"
                        accept="image/*"
                        ref={fileInputRefCategoryImage}
                        onChange={handleCategoryFileChange}
                        className="w-full mt-2"
                      />
                      {fileError && <div className="errormessage text-red-500 mt-2">{fileError}</div>}
                    </div>

                    {preview && (
                      <div className="preview-container mb-4">
                        <img src={preview} alt="Logo Preview" className="logo-preview w-32 h-32 object-cover rounded" />
                      </div>
                    )}

                    <div className="form-group">
                      <button type="submit" className="btn btn-primary ">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>

            )}
            {activeTab === 'Games' && (
              <div className="shadow-lg p-4 border bg-white">

                <button
                  onClick={toggleText}
                  className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
                >
                  {isNewGame ? "Add New Game" : "All Games"}
                </button>
                {isNewGame ? (
                  <div>
                    <h3 className="text-lg font-bold mb-4 mt-4 text-left pt-4">All Games</h3>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8 mt-5 mb-12">
                      {AllGames && AllGames.length > 0 && AllGames.map((card) => (
                        <div
                          key={card.id}
                          className="card1 group relative bg-white rounded-md mb-8"
                          style={{ width: '200px', height: '300px' }}
                        >
                          <img
                            src={S3_BASE_URL + card?.gameBanner}
                            alt={card.title}
                            className="imgFit"
                          />
                          <h3 className="mt-2 font-semibold mb-4 text-left">{card.title}</h3>

                          <div className="absolute top-2 right-2 flex space-x-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            <button
                              className="bg-orange-500 text-white text-sm py-1 px-3 rounded"
                              onClick={() => editGame(card)}
                            >
                              Edit
                            </button>
                            <button
                              className="bg-red-500 text-white text-sm py-1 px-3 rounded"
                              onClick={() => deleteGame(card)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      ))}
                      {AllGames && AllGames.length === 0 && <p>No Games Found</p>}
                    </div>
                  </div>

                ) : (
                  <div>
                    <h3 className="text-lg font-bold mb-4 text-left">{isEditing ? 'Update' : 'Add'} Game </h3>
                    <form class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4" >

                      <div>
                        <label for="firstName" class="block text-sm font-medium text-gray-700 text-left">Title</label>
                        <input type="text"
                          id="title"
                          name="title"
                          value={formData.title}
                          onChange={handleTextChange}
                          placeholder="Enter title"
                          class="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                        {errors.title && <p className="text-red-500 text-sm text-left">{errors.title}</p>}
                      </div>
                      <div>
                        <label for="firstName" class="block text-sm font-medium text-gray-700 text-left">Description</label>
                        <input type="text"
                          id="description"
                          name="description"
                          value={formData.description}
                          placeholder="Enter description"
                          onChange={handleTextChange} class="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                        {errors.description && <p className="text-red-500 text-sm text-left">{errors.description}</p>}
                      </div>

                      <div>
                        <label for="firstName" class="block text-sm font-medium text-gray-700 text-left">Feature</label>
                        <input type="text" id="feature"
                          name="feature"
                          value={formData.feature}
                          placeholder="Enter feature"
                          onChange={handleTextChange} class="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                        {errors.feature && <p className="text-red-500 text-sm text-left">{errors.feature}</p>}
                      </div>
                      <div>
                        <label for="firstName" class="block text-sm font-medium text-gray-700 text-left">Minimum People that can play</label>
                        <input type="number"
                          id="minPlayer"
                          name="minPlayer"
                          value={formData.minPlayer}
                          onChange={handleTextChange}
                          min="1"

                          placeholder="Enter "
                          class="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                        {errors.minPlayer && <p className="text-red-500 text-sm text-left">{errors.minPlayer}</p>}
                      </div>
                      <div>
                        <label for="firstName" class="block text-sm font-medium text-gray-700 text-left">Maximum People that can play</label>
                        <input type="number"
                          id="maxPlayer"
                          name="maxPlayer"
                          value={formData.maxPlayer}
                          onChange={handleTextChange}
                          min="1"
                          placeholder="Enter "
                          class="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                        {errors.maxPlayer && <p className="text-red-500 text-sm text-left">{errors.maxPlayer}</p>}
                      </div>

                      <div>
                        <label for="lastName" class="block text-sm font-medium text-gray-700 text-left">Duration (in minutes)</label>
                        <input type="number"
                          id="duration"
                          name="duration"
                          value={formData.duration}
                          placeholder="Enter duration "
                          onChange={handleTextChange} class="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                        {errors.duration && <p className="text-red-500 text-sm text-left">{errors.duration}</p>}
                      </div>


                      <div>
                        <label for="email" class="block text-sm font-medium text-gray-700 text-left">Cost of the event (INR)</label>
                        <input type="number"
                          id="eventCost"
                          name="eventCost"
                          value={formData.eventCost}
                          placeholder="Enter cost of event"
                          onChange={handleTextChange} class="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                        {errors.eventCost && <p className="text-red-500 text-sm text-left">{errors.eventCost}</p>}
                      </div>

                      <div>
                        <label for="email" class="block text-sm font-medium text-gray-700 text-left">Cost of the event (USD)</label>
                        <input type="number"
                          id="eventCostUSD"
                          name="eventCostUSD"
                          value={formData.eventCostUSD}
                          placeholder="Enter cost of event"
                          onChange={handleTextChange} class="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                        {errors.eventCostUSD && <p className="text-red-500 text-sm text-left">{errors.eventCostUSD}</p>}
                      </div>
                      <div>
                        <label for="phone" class="block text-sm font-medium text-gray-700 text-left">Type of event</label>
                        <select
                          id="eventType"
                          name="eventType"
                          value={formData.eventType}
                          onChange={handleTextChange}

                          className="w-full border border-gray-300 h-[40px] p-2 rounded-md"
                        >
                          <option value="">Select Game Type</option>
                          <option value="online">Online</option>
                          <option value="offline">Offline</option>
                        </select>
                        {errors.eventType && <p className="text-red-500 text-sm block text-left">{errors.eventType}</p>}
                      </div>

                      <div>
                        <label for="address" class="block text-sm font-medium text-gray-700 text-left">Category</label>

                        <Select
                          value={multiSelectOptions}
                          onChange={handleMultiSelectChange}
                          options={bookEventCategories}
                          isMulti
                          placeholder="Select Category"
                        />
                        {errors.categories && <p className="text-red-500 text-sm text-left">{errors.categories}</p>}
                      </div>

                      <div>
                        <label for="email" class="block text-sm font-medium text-gray-700 text-left">Difficulty Level</label>
                        <select
                          id="difficultylevel"
                          name="difficultylevel"
                          value={formData.difficultylevel}
                          onChange={handleTextChange}
                          className="w-full border border-gray-300 h-[40px] p-2 rounded-md"
                        >
                          <option value="">Select Difficulty Level</option>
                          <option value="low">Low</option>
                          <option value="medium">Medium</option>
                          <option value="high">High</option>
                        </select>
                        {errors.difficultylevel && <p className="text-red-500 text-sm text-left">{errors.difficultylevel}</p>}
                      </div>


                      <div>
                        <label for="phone" class="block text-sm font-medium text-gray-700 text-left">Game Type</label>
                        <select
                          id="gameType"
                          name="gameType"
                          value={formData.gameType}
                          onChange={handleTextChange}
                          className="w-full border border-gray-300 h-[40px] p-2 rounded-md"
                        >
                          <option value="">Select Game Type</option>
                          <option value="individual">Individual</option>
                          <option value="team">Team</option>

                        </select>
                        {errors.gameType && <p className="text-red-500 text-sm text-left">{errors.gameType}</p>}
                      </div>

                      <div>
                        <label for="address" class="block text-sm font-medium text-gray-700 text-left">Booking Type</label>
                        <select
                          id="bookingType"
                          name="bookingType"
                          value={formData.bookingType}
                          onChange={handleTextChange}
                          className="w-full border border-gray-300 h-[40px] p-2 rounded-md"
                        >
                          <option value="">Select Booking Type</option>
                          <option value="instant">Instant</option>
                          <option value="requestdate">Request Date</option>
                        </select>
                        {errors.bookingType && <p className="text-red-500 text-sm text-left">{errors.bookingType}</p>}
                      </div>
                      <div>
                        <label for="address" class="block text-sm font-medium text-gray-700 text-left">Guarantee Type</label>
                        <select
                          id="guaranteeType"
                          name="guaranteeType"
                          value={formData.guaranteeType}
                          onChange={handleTextChange}
                          className="w-full border border-gray-300 h-[40px] p-2 rounded-md"
                        >
                          <option value="">Select Guarantee Type</option>
                          <option value="extramile">Exramile</option>
                          <option value="host">Host</option>
                        </select>
                        {errors.guaranteeType && <p className="text-red-500 text-sm text-left">{errors.guaranteeType}</p>}
                      </div>

                      <div>
                        <label for="address" class="block text-sm font-medium text-gray-700 text-left">Head Count</label>
                        <input type="number"
                          id="headCount"
                          name="headCount"
                          value={formData.headCount}
                          onChange={handleTextChange}
                          placeholder="Enter"
                          min="1" class="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                        {errors.headCount && <p className="text-red-500 text-sm text-left">{errors.headCount}</p>}
                      </div>


                      <div>
                        <label for="address" class="block text-sm font-medium text-gray-700 text-left">How It Works</label>
                        <input type="text"
                          id="howItWorks"
                          name="howItWorks"
                          value={formData.howItWorks}
                          onChange={handleTextChange}
                          placeholder="Enter"
                          class="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                        {errors.howItWorks && <p className="text-red-500 text-sm text-left">{errors.howItWorks}</p>}
                      </div>

                      <div>
                        <label for="address" class="block text-sm font-medium text-gray-700 text-left">Agenda Of The Show</label>
                        <input type="text"
                          id="agenda"
                          name="agenda"
                          value={formData.agenda}
                          onChange={handleTextChange}
                          placeholder="Enter"
                          class="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                        {errors.agenda && <p className="text-red-500 text-sm text-left">{errors.agenda}</p>}
                      </div>
                      <div>
                        <label for="address" class="block text-sm font-medium text-gray-700 text-left">Things You Will Need</label>
                        <input type="text"
                          id="thingsYouNeed"
                          name="thingsYouNeed"
                          value={formData.thingsYouNeed}
                          onChange={handleTextChange}
                          placeholder="Enter"
                          class="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                        {errors.thingsYouNeed && <p className="text-red-500 text-sm text-left">{errors.thingsYouNeed}</p>}
                      </div>
                      {/* <div>
                        <label for="address" class="block text-sm font-medium text-gray-700 text-left">How To Join</label>
                        <input type="text"
                          id="howToJoin"
                          name="howToJoin"
                          value={formData.howToJoin}
                          onChange={handleTextChange}
                          placeholder="Enter"
                          class="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                        {errors.howToJoin && <p className="text-red-500 text-sm text-left">{errors.howToJoin}</p>}
                      </div> */}



                      <div>
                        <label for="address" class="block text-sm font-medium text-gray-700 text-left">Event use breakout room</label>
                        <select
                          id="breakOutRoom"
                          name="breakOutRoom"
                          value={formData.breakOutRoom}
                          onChange={handleTextChange}
                          className="w-full border border-gray-300 h-[40px] p-2 rounded-md"
                        >
                          <option value="">Select </option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                        {errors.breakOutRoom && <p className="text-red-500 text-sm text-left">{errors.breakOutRoom}</p>}
                      </div>

                       {formData.breakOutRoom === 'yes' && (
                      <div>
                        <label for="firstName" class="block text-sm font-medium text-gray-700 text-left">Break out room size</label>
                        <input type="number"
                          id="breakOutRoomSize"
                          name="breakOutRoomSize"
                          value={formData.breakOutRoomSize}
                          onChange={handleTextChange}
                          min="1"
                          placeholder="Enter "
                          class="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
                        {errors.breakOutRoomSize && <p className="text-red-500 text-sm text-left">{errors.breakOutRoomSize}</p>}
                      </div>
 )}
                      <div>
                        <label for="address" class="block text-sm font-medium text-gray-700 text-left">How To Join</label>

                        <Select
                          value={multiSelectOptionsPlatforms}
                          onChange={handleMultiSelectChangePltforms}
                          options={bookEventplatforms}
                          isMulti
                          placeholder="Select Category"
                        />
                        {errors.supportedPlatforms && <p className="text-red-500 text-sm text-left">{errors.supportedPlatforms}</p>}
                      </div>
                      <div className="col-span-full mt-4">
                        <div className="container-feature">
                          <div className="flex items-start gap-4">


                            {isEditing ? (
                              <>
                                <div className="form-group mb-4 w-full md:w-1/2">
                                  <div className="text-left">
                                    <label htmlFor="logo" className="block text-lg font-bold mb-4 text-left">Event Thumbnail</label>
                                    <label htmlFor="logo" className="text-gray-500">Size: 400 x 200 px</label>
                                  </div>

                                  <input
                                    type="file"
                                    id="gameBanner"
                                    name="gameBanner"
                                    accept="image/*"
                                    ref={fileInputRefGameBanner}
                                    onChange={handleGameBannerChangeOnEdit}
                                    className="w-full mt-2"
                                  />
                                  {gameBannerFileError && (
                                    <div className="errormessage text-red-500 mt-2">{gameBannerFileError}</div>
                                  )}


                                </div>

                                {gameBannerPreview && (
                                  <div className="preview-container mb-4 w-full md:w-1/3">
                                    <img
                                      src={S3_BASE_URL + gameBannerPreview}
                                      alt="Logo Preview"
                                      className="logo-preview w-32 h-32 object-cover rounded"
                                    />
                                  </div>
                                )}

                              </>
                            ) : (
                              <>
                                <div className="form-group mb-4 w-full md:w-1/2">
                                  <div className="text-left">
                                    <h3 className=" font-bold mb-4 text-left">Event Thumbnail</h3>

                                    <label htmlFor="logo" className="text-gray-500">Size: 400 x 200 px</label>
                                  </div>

                                  <input
                                    type="file"
                                    id="gameBanner"
                                    name="gameBanner"
                                    accept="image/*"
                                    ref={fileInputRefGameBanner}
                                    onChange={handleGameBannerChange}
                                    className="w-full mt-2"
                                  />
                                  {gameBannerFileError && (
                                    <div className="errormessage text-red-500 mt-2">{gameBannerFileError}</div>
                                  )}


                                </div>

                                {gameBannerPreview && (
                                  <div className="preview-container mb-4 w-full md:w-1/3">
                                    <img
                                      src={gameBannerPreview}
                                      alt="Logo Preview"
                                      className="logo-preview w-32 h-32 object-cover rounded"
                                    />
                                  </div>
                                )}

                              </>
                            )}
                          </div>
                        </div>
                      </div>


                      <div className="col-span-full mt-4">
                        <div className="container-feature">
                          <h3 className=" font-bold mb-4 text-left"> Main Images/Videos</h3>
                          <p className="text-left mb-4">Size 850 x 950</p>
                          <div className="formRow">
                            <div className="form-column">
                              <h3 className="file-title">Games Media</h3>
                            </div>
                            <div className="form-column remove-column">
                              <button onClick={(event) => { event.preventDefault(); addMainGamesRow(); }} className="add-row-button">Add </button>
                            </div>
                          </div>

                          {isEditing ? (
                            <>
                              {mainGamesRows.map((row, rowIndex) => (
                                <div key={rowIndex} className="formRow">
                                  <div className="form-column">
                                    <input
                                      type="file"
                                      accept="image/x-png,image/jpg,image/jpeg,image/gif,video/mp4,video/webm"
                                      onChange={(e) => handleMainFileUploadOnEdit(rowIndex, e)}
                                    />

                                    {row.errors.image && <div className="errMsg">{row.errors.image}</div>}
                                  </div>
                                  <div className="form-column">
                                    {row?.images && row.images.length > 0 ? (
                                      row.images[1]?.endsWith('.mp4') || row.images[1]?.endsWith('.webm') ? (
                                        <video
                                          src={S3_BASE_URL + row.images[1]}
                                          controls
                                          style={{
                                            height: '150px',
                                            width: '150px',
                                            objectFit: 'cover',
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={S3_BASE_URL + row.images[1]}
                                          alt="media preview"
                                          style={{
                                            height: '150px',
                                            width: '150px',
                                            objectFit: 'cover',
                                          }}
                                        />
                                      )
                                    ) : null}
                                  </div>
                                  <div className="form-column remove-column">
                                    <button
                                      onClick={(event) => { event.preventDefault(); removeMainRow(rowIndex, row); }}

                                      className="remove-button"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : (
                            <>
                              {mainGamesRows.map((row, rowIndex) => (
                                <div key={rowIndex} className="formRow">
                                  <div className="form-column">
                                    <input
                                      type="file"
                                      accept="image/x-png,image/jpg,image/jpeg,image/gif,video/mp4,video/webm"
                                      onChange={(e) => handleMainGamesFileUpload(rowIndex, e)}
                                    />
                                    {row.errors.image && <div className="errMsg">{row.errors.image}</div>}
                                  </div>
                                  <div className="form-column">
                                    {row.image && (
                                      row.image.type.includes('video') ? (
                                        <video
                                          src={URL.createObjectURL(row.image)}
                                          controls
                                          style={{
                                            height: '150px',
                                            width: '150px',
                                            objectFit: 'cover',
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={URL.createObjectURL(row.image)}
                                          alt="preview"
                                          style={{
                                            height: '150px',
                                            width: '150px',
                                            objectFit: 'cover',
                                          }}
                                        />
                                      )
                                    )}
                                  </div>
                                  <div className="form-column remove-column">
                                    <button
                                      onClick={(event) => { event.preventDefault(); removeMainRow(rowIndex); }}

                                      className="remove-button"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                        <div className="container-feature">
                          <h3 className=" font-bold mb-4 text-left"> Sub Images/Videos</h3>
                          <p className="text-left mb-4">Size 500 x 450</p>
                          <div className="formRow">
                            <div className="form-column">
                              <h3 className="file-title">Games Media</h3>
                            </div>
                            <div className="form-column remove-column">
                              <button onClick={(event) => { event.preventDefault(); addGamesRow(); }} className="add-row-button">Add </button>
                            </div>
                          </div>

                          {isEditing ? (
                            <>
                              {gamesRows.map((row, rowIndex) => (
                                <div key={rowIndex} className="formRow">
                                  <div className="form-column">
                                    <input
                                      type="file"
                                      accept="image/x-png,image/jpg,image/jpeg,image/gif,video/mp4,video/webm"
                                      onChange={(e) => handleGamesFileUploadOnEdit(rowIndex, e)}
                                    />

                                    {row.errors.image && <div className="errMsg">{row.errors.image}</div>}
                                  </div>
                                  <div className="form-column">
                                    {row?.images && row.images.length > 0 ? (
                                      row.images[1]?.endsWith('.mp4') || row.images[1]?.endsWith('.webm') ? (
                                        <video
                                          src={S3_BASE_URL + row.images[1]}
                                          controls
                                          style={{
                                            height: '150px',
                                            width: '150px',
                                            objectFit: 'cover',
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={S3_BASE_URL + row.images[1]}
                                          alt="media preview"
                                          style={{
                                            height: '150px',
                                            width: '150px',
                                            objectFit: 'cover',
                                          }}
                                        />
                                      )
                                    ) : null}
                                  </div>
                                  <div className="form-column remove-column">
                                    <button
                                      onClick={(event) => { event.preventDefault(); removeGamesRow(rowIndex, row); }}

                                      className="remove-button"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : (
                            <>
                              {gamesRows.map((row, rowIndex) => (
                                <div key={rowIndex} className="formRow">
                                  <div className="form-column">
                                    <input
                                      type="file"
                                      accept="image/x-png,image/jpg,image/jpeg,image/gif,video/mp4,video/webm"
                                      onChange={(e) => handleGamesFileUpload(rowIndex, e)}
                                    />
                                    {row.errors.image && <div className="errMsg">{row.errors.image}</div>}
                                  </div>
                                  <div className="form-column">
                                    {row.image && (
                                      row.image.type.includes('video') ? (
                                        <video
                                          src={URL.createObjectURL(row.image)}
                                          controls
                                          style={{
                                            height: '150px',
                                            width: '150px',
                                            objectFit: 'cover',
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={URL.createObjectURL(row.image)}
                                          alt="preview"
                                          style={{
                                            height: '150px',
                                            width: '150px',
                                            objectFit: 'cover',
                                          }}
                                        />
                                      )
                                    )}
                                  </div>
                                  <div className="form-column remove-column">
                                    <button
                                      onClick={(event) => { event.preventDefault(); removeGamesRow(rowIndex); }}

                                      className="remove-button"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>


                        <div className="container-feature">
                          <h3 className=" font-bold mb-4 text-left">Add Faq's           <input
                        type="checkbox"
                        checked={showFaqs} 
                        className="ml-3"
                        onChange={handleFaqChange}
                    /></h3>
                    {showFaqs && (  <div>
                          <div className="formRow">
                            <div className="form-column">
                              <h3 className="field-title">FAQ Title</h3>
                            </div>
                            <div className="form-column">
                              <h3 className="field-title">FAQ Description</h3>
                            </div>
                            <div className="form-column remove-column">
                              <button type="button" onClick={(event) => { event.preventDefault(); addRowFaq(); }}
                                className="add-row-button">
                                Add Row
                              </button>
                            </div>
                          </div>

                          {rowsFaq.map((row, rowIndex) => (
                            <div key={rowIndex} className="formRow">
                              <div className="form-column">
                                <input
                                  type="text"
                                  placeholder="Enter FAQ Title"
                                  value={row.title}
                                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                  onChange={(e) => handleInputChange(rowIndex, 'title', e.target.value)}
                                />
                                {row.errors.title && <div className="errMsg">{row.errors.title}</div>}
                              </div>
                              <div className="form-column">
                                <textarea
                                  placeholder="Enter FAQ Description"
                                  value={row.description}
                                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                  onChange={(e) => handleInputChange(rowIndex, 'description', e.target.value)}
                                />
                                {row.errors.description && <div className="errMsg">{row.errors.description}</div>}
                              </div>
                              <div className="form-column remove-column">
                                <button type="button" onClick={(event) => { event.preventDefault(); removeRowFaq(rowIndex); }} className="remove-button">
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}

</div>)}
                        </div>

                        <div className="container-feature">
                    
                          <h3 className="font-bold mb-4 text-left">Add Testimonial  
                              <input
                        type="checkbox"
                        checked={showTestimonials} 
                        className="ml-3"
                        onChange={handleTestimonialChange}
                    />
                    </h3>
                    {showTestimonials && (  <div>
                          <p className="text-left mb-4">Size 300 x 300</p>
                          <div className="formRow">
                            <div className="form-column">
                              <h3 className="file-title">Testimonial Image</h3>
                            </div>
                            <div className="form-column">

                            </div>
                            <div className="form-column">
                              <h3 className="file-title">Name</h3>
                            </div>
                            <div className="form-column">
                              <h3 className="file-title">Designation</h3>
                            </div>
                            <div className="form-column">
                              <h3 className="file-title">Comment</h3>
                            </div>
                            <div className="form-column remove-column">
                              <button onClick={addTestimonialRow} className="add-row-button">Add Testimonial</button>
                            </div>
                          </div>

                          {isEditing ? (
                            <>
                              <>
                                {testimonialRows.map((row, rowIndex) => (
                                  <div key={rowIndex} className="formRow">
                                    <div className="form-column">
                                      <input
                                        type="file"
                                        accept="image/x-png,image/jpg,image/jpeg,image/gif"
                                        onChange={(e) => handleTestimonialFileUpload(rowIndex, e)}
                                      />

                                      {row.errors.image && <div className="errMsg">{row.errors.image}</div>}
                                    </div>
                                    <div className="form-column">
                                      {row.image ? (
                                        <img
                                          src={typeof row.image === 'string' ? S3_BASE_URL + row.image : URL.createObjectURL(row.image)}
                                          alt="preview"
                                          style={{
                                            height: '50px',
                                            width: '50px',
                                            borderRadius: '50%',
                                            objectFit: 'cover',
                                          }}
                                        />
                                      ) : (
                                        <div>No image uploaded</div>
                                      )}
                                    </div>
                                    <div className="form-column">
                                      <input
                                        type="text"
                                        placeholder="Enter Name"
                                        value={row.name}
                                        className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        onChange={(e) => handleTestimonialInputChange(rowIndex, 'name', e.target.value)}
                                      />
                                      {row.errors.name && <div className="errMsg">{row.errors.name}</div>}
                                    </div>
                                    <div className="form-column">
                                      <input
                                        type="text"
                                        placeholder="Enter Designation"
                                        value={row.designation}
                                        className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        onChange={(e) => handleTestimonialInputChange(rowIndex, 'designation', e.target.value)}
                                      />
                                      {row.errors.designation && <div className="errMsg">{row.errors.designation}</div>}
                                    </div>
                                    <div className="form-column">
                                      <textarea
                                        placeholder="Enter Comment"
                                        value={row.comment}
                                        className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        onChange={(e) => handleTestimonialInputChange(rowIndex, 'comment', e.target.value)}
                                      />
                                      {row.errors.comment && <div className="errMsg">{row.errors.comment}</div>}
                                    </div>
                                    <div className="form-column remove-column">
                                      <button
                                        onClick={(event) => { event.preventDefault(); removeTestimonialRow(rowIndex, row); }}

                                        className="remove-button"
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </>
                            </>
                          ) : (
                            <>

                              {testimonialRows.map((row, rowIndex) => (
                                <div key={rowIndex} className="formRow">
                                  <div className="form-column">
                                    <input
                                      type="file"
                                      accept="image/x-png,image/jpg,image/jpeg,image/gif"
                                      onChange={(e) => handleTestimonialFileUpload(rowIndex, e)}
                                    />

                                    {row.errors.image && <div className="errMsg">{row.errors.image}</div>}
                                  </div>
                                  <div className="form-column">
                                    {row.image && (
                                      <img
                                        src={URL.createObjectURL(row.image)}
                                        alt="preview"
                                        className="preview-image"
                                        style={{
                                          height: '50px',
                                          width: '50px',
                                          borderRadius: '50%',
                                          objectFit: 'cover',
                                        }}
                                      />
                                    )}
                                  </div>
                                  <div className="form-column">
                                    <input
                                      type="text"
                                      placeholder="Enter Name"
                                      value={row.name}
                                      className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                      onChange={(e) => handleTestimonialInputChange(rowIndex, 'name', e.target.value)}
                                    />
                                    {row.errors.name && <div className="errMsg">{row.errors.name}</div>}
                                  </div>
                                  <div className="form-column">
                                    <input
                                      type="text"
                                      placeholder="Enter Designation"
                                      value={row.designation}
                                      className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                      onChange={(e) => handleTestimonialInputChange(rowIndex, 'designation', e.target.value)}
                                    />
                                    {row.errors.designation && <div className="errMsg">{row.errors.designation}</div>}
                                  </div>
                                  <div className="form-column">
                                    <textarea
                                      placeholder="Enter Comment"
                                      value={row.comment}
                                      className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                      onChange={(e) => handleTestimonialInputChange(rowIndex, 'comment', e.target.value)}
                                    />
                                    {row.errors.comment && <div className="errMsg">{row.errors.comment}</div>}
                                  </div>
                                  <div className="form-column remove-column">
                                    <button
                                      onClick={(event) => { event.preventDefault(); removeTestimonialRow(rowIndex); }}

                                      className="remove-button"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </>

                          )}
</div>)}
                        </div>
                        <button onClick={AddNewGame} className="btn btn-primary mt-12">Submit</button>
                      </div>

                    </form></div>
                )}



              </div>




            )}
            {activeTab === 'Testimonials' && (
              <div>
                <div style={{ height: 'auto', marginBottom: '20px', backgroundColor: 'white', padding: '10px', borderRadius: '8px' }}>
                  <h3 className="text-lg font-bold mb-4 text-left">Disable Dates</h3>
                  <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    selectable
                    onSelectSlot={handleSelectSlot}
                    onSelectEvent={handleSelectEvent}
                    eventPropGetter={eventPropGetter}
                    style={{ height: 500 }}
                    defaultView="month"
                    views={['month', 'agenda']}

                  />
                  <button
                    onClick={handleGetAllDates}
                    className="btn btn-primary mt-12"
                  >
                    Block Selected Dates
                  </button>
                </div>


                <form onSubmit={handleBannerSubmit} className="p-6 w-full mx-auto bg-white rounded-lg mb-12">
                  <div className="flex flex-wrap">

                    <div className="w-full md:w-1/2 mb-4 pr-2">
                      <h3 className="text-lg font-bold mb-4 text-left">Upload Desktop Banner</h3>
                      <p className="text-left mb-4">Size 1920 x 600</p>
                      <input
                        type="file"
                        id="bookEventBanner"
                        accept="image/jpeg, image/png, image/gif"
                        ref={InputRefDesktopBanner}
                        onChange={handleBannerChange}
                        className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                      />
                      {!desktopBannerPreview && bannererror && <p className="text-red-500 text-sm mb-4 text-left">{bannererror}</p>}
                      {desktopBannerPreview && (
                        <div className="mt-4">
                          <h3 className="text-sm font-medium text-gray-700 mb-2">Desktop Banner Preview</h3>
                          <img src={desktopBannerPreview} alt="Selected" className="w-full h-48 object-cover rounded-md shadow-sm" />
                        </div>
                      )}
                    </div>


                    <div className="w-full md:w-1/2 mb-4 pl-2">
                      <h3 className="text-lg font-bold mb-4 text-left">Upload Mobile Banner</h3>
                      <p className="text-left mb-4">Size 360 x 477</p>
                      <input
                        type="file"
                        id="bookEventMobileBanner"
                        accept="image/jpeg, image/png, image/gif"
                        ref={InputRefMobileBanner}
                        onChange={handleMobileBannerChange}
                        className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                      />
                      {!mobileBannerPreview && mobileBannererror && <p className="text-red-500 text-sm mb-4 text-left">{mobileBannererror}</p>}
                      {mobileBannerPreview && (
                        <div className="mt-4">
                          <h3 className="text-sm font-medium text-gray-700 mb-2">Mobile Banner Preview</h3>
                          <img src={mobileBannerPreview} alt="Selected" className="w-full h-48 object-cover rounded-md shadow-sm" />
                        </div>
                      )}
                    </div>
                  </div>


                  <button type="submit" className=" py-2 px-4 bg-orange-600 text-white rounded-lg hover:bg-blue-700 transition-all">
                    Submit
                  </button>
                </form>
                <br />
                <DataTable
                  title="All Banners"
                  columns={bannerColumns}
                  data={banners}
                  className="rdt_TableHead"
                  responsive
                  striped
                  highlightOnHover
                  pagination
                  customStyles={customStyles}
                />
              </div>

            )}
            {activeTab === 'platforms' && (
              <div>

             

<div className="max-w-md mx-auto p-4 bg-white rounded shadow-md">
            <form onSubmit={submitPlatformData} className="flex flex-col space-y-4">
                <input
                    type="text"
                    value={platformName}
                    onChange={handlePlatformNameChange}
                    placeholder="Enter platform name"
                    className="border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                />
                {platformNameerror && (
                    <p className="text-red-500">{platformNameerror}</p>
                )}
                <button
                    type="submit"
                    className="bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-200"
                >
                    Add
                </button>
            </form>
      
        </div>
                <br />
                <DataTable
                  title="Platforms Allowed"
                  columns={platformColumns}
                  data={bookEventplatforms}
                  className="rdt_TableHead"
                  responsive
                  striped
                  highlightOnHover
                  pagination
                  customStyles={customStyles}
                />
              </div>

            )}
          </div>
        </div>

      </PageLayout>
    </div>
  );
};
export default BookEventAdmin;