const sidebarContentAdmin = [
  { title: "Analytics", redirectLink: "/analytics" },


  { title: "AnalyticsTwo", redirectLink: "/analyticsTwo" },


  { title: "Homepage", redirectLink: "/" },
  { title: "Payments", redirectLink: "/payment-details" },
  // { title: "Add New Organisation", redirectLink: "/addneworganisation" },
  { title: "Organisation Details", redirectLink: "/org-details-admin" },
  // { title: "Edit Organisation", redirectLink: "/editorganisation" },
  { title: "Games", redirectLink: "/all-games" },

  { title: "Logos", redirectLink: "/org-logos" },
  { title: "Testimonials", redirectLink: "/feedback" },
  { title: "Onsite Quotes", redirectLink: "/onsite" },
  { title: "Categories", redirectLink: "/category/add-or-edit" },
  { title: "Objectives", redirectLink: "/objective/add-or-edit" },
  { title: "SSO Details", redirectLink: "/ssoadminpage" },
  { title: "Webinars History", redirectLink: "/webinar/history/add" },
  { title: "Footer Page", redirectLink: "/footer" },

  { title: "Profile", redirectLink: "/account" },
  // { title: "Polls", redirectLink: "/all-polls" },
  { title: "DDDT Links", redirectLink: "/dddt-links" },
  { title: "Book An Event", redirectLink: "/bookevent" },
  { title: "Event Bookings", redirectLink: "/event-booking-details" },
  { title: "Event Questions", redirectLink: "/event-booking-questions" },

  // { title: "Dhoom Dham Diwali", redirectLink: "/dhoom-dham-diwali" },
  { title: "Sign out", redirectLink: "/" },

  // { title: "Webinar", redirectLink: "/" },
];

export default sidebarContentAdmin;